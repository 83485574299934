<template>
  <img
    alt="Logo"
    :src="dark? LogoLight:LogoDark"
    :class="{'cursor-pointer':clickable}"
    @click="onClick"
  >
</template>
<script setup>
import { useRouter } from 'vue-router'
import LogoDark from '@/assets/common/logo-dark.svg'
import LogoLight from '@/assets/common/logo-light.svg'

const router = useRouter()

const props = defineProps({
  dark: {
    type: Boolean,
    default: true
  },
  clickable: {
    type: Boolean,
    default: false
  }
})

function onClick () {
  props.clickable && router.push('/')
}
</script>
